import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment, serviceBaseUrl } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  pipe = new DatePipe('en-IN');
  EquipmentApi = environment.EquipmentApi;
  SharedAlertsApi = environment.SharedAlertsApi;


  constructor(private httpClient: HttpClient) { }

  getMonthFromDate(date: Date): string {
    return this.pipe.transform(date, 'MMMM', 'UTC') || '';
  }

  public getNews(): Observable<any> {
    return this.httpClient.get(environment.NewsApi);
  }

  public getWeather(location: string): Observable<any> {
    return this.httpClient.get(
      environment.WeatherApi + 'city=' + location + environment.WeatherKey
    );
  }

  public getProfile(): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(serviceBaseUrl + 'auth/profile', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else return of(null);
  }

  public getSitesData(): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(serviceBaseUrl + 'sites', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else return of(null);
  }

  public getMultiSitesData(month: String, year: String): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'account/sites/comparison?month=' +
        month +
        '&year=' +
        year,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getHomepageApi(
    month: String,
    year: String,
    site_slug: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'site/home?month=' +
        month +
        '&year=' +
        year +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getAccountLandingInsightsData(
    month: String,
    year: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl + 'account/home?month=' + month + '&year=' + year,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    }
    else return of(null);
  }

  public getMyprofileApi(): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(serviceBaseUrl + 'auth/me', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    } else return of(null);
  }

  public getHeatMapData(
    month: String,
    year: String,
    site_slug: String,
    type?: String
  ): Observable<any> {
    const url =
      type === 'daily'
        ? 'site/consumption/daily/heatmap?month='
        : 'site/consumption/month/hour?month=';
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        url +
        month +
        '&year=' +
        year +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getOperatingHoursData(
    month: String,
    year: String,
    site_slug: String,
    type: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'site/consumption/operating/weekday?month=' +
        month +
        '&year=' +
        year +
        '&field=' +
        type +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getHourlyCostData(
    month: String,
    year: String,
    site_slug: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'site/cost/hour?month=' +
        month +
        '&year=' +
        year +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getSensorStatusData(site_slug: String): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'site/devices?status=1&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getMonthlyUsageData(year: String, site_slug: String): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'site/consumption/yearly/month?year=' +
        year +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }






  public getAccountMonthlyUsageData(year: String): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl + 'account/consumption/yearly/month?year=' + year,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getStockChartData(
    month: Date,
    year: Date,
    type: String,
    graph: String,
    site_slug: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'insights/' +
        graph +
        '/hourly?month=' +
        month +
        '&year=' +
        +year +
        '&field=' +
        type +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
    }
    else return of(null);
  }

  public getConsumptionDayFiveMinute
    (
      date: any[] = [],
      field: string,
      site_id: string,
    ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'insights/consumption/day/minute?field=' +
        field +
        '&date=' +
        date +
        '&site_id=' +
        (!site_id ? localStorage.getItem('site_slug') : site_id),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }



  postConsumptionDayFiveMinute(data: any,): Observable<any> {
    // let formdata = new FormData();
    // formdata.append('dates', date);
    // formdata.append('field', field);
    // formdata.append('site_id', site_slug || localStorage.getItem('site_slug'));

    return this.httpClient.post(`${serviceBaseUrl}insights/consumption/day/minute`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  }







  public getPhaseApplianceData(
    month: Date,
    year: Date,
    type: String,
    site_slug: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        '/phase/distribution?&month=' +
        month +
        '&year=' +
        +year +
        '&field=' +
        type +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getLoadCurveData(
    duration: String,
    site_slug: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'insights/load_curve?&duration=' +
        duration +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      )
    } else return of(null);
  }

  public getMinMaxData(
    month: Date,
    year: Date,
    duration: String,
    site_slug: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'insights/minmax/hourly?month=' +
        month +
        '&year=' +
        +year +
        '&duration=' +
        duration +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getSavingsData(
    month: any,
    year: any,
    site_slug: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'insights/savings/consumption?month=' +
        month +
        '&year=' +
        +year +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else
      return of(null);
  }

  public getEvents(monthList: any, site_slug: String): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'site/events?month=' +
        monthList.month +
        '&year=' +
        +monthList.year +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public deleteEvents(id: any): Observable<any> {
    return this.httpClient.delete(
      serviceBaseUrl +
      'site/events/' + id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

  public getHistoricalMonthlyConsumption(
    month: any,
    year: any,
    site_slug: String
  ): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'insights/stats/monthly?month=' +
        month +
        '&year=' +
        year +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getDayAnalysisData(
    month: String,
    year: String,
    site_slug: any
  ): Observable<any> {
    if (localStorage.getItem('token') || site_slug) {
      return this.httpClient.get(
        serviceBaseUrl +
        'insights/consumption/daily?month=' +
        month +
        '&year=' +
        year +
        '&site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug),
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  postevent(eventData: any, site_slug: any): Observable<any> {
    if (!site_slug && !localStorage.getItem('site_slug')) return of(null);
    let formdata = new FormData();
    formdata.append(
      'date',
      this.pipe.transform(eventData.date, 'YYYY-MM-dd') || ''
    );
    formdata.append('name', eventData.name);
    formdata.append('site_id', site_slug || localStorage.getItem('site_slug'));
    formdata.append('type', eventData.type);

    return this.httpClient.post(`${serviceBaseUrl}site/events`, formdata, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  }

  getSiteComparisonData(month: String, year: String): Observable<any> {
    return this.httpClient.get(
      serviceBaseUrl +
      'account/sites/comparison?month=' +
      month +
      '&year=' +
      year,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

  public getRecommendations(
    selectedMonth: any,
    site_slug: string
  ): Observable<any> {
    if (localStorage.getItem('token') || site_slug) {
      return this.httpClient.get(
        serviceBaseUrl +
        'site/recommendations?site_id=' +
        (!site_slug ? localStorage.getItem('site_slug') : site_slug) +
        '&year=' +
        +selectedMonth.year +
        '&month=' +
        selectedMonth.month,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getCompanySites(month: String, year: String, score: number): Observable<any> {
    return this.httpClient.get(serviceBaseUrl + 'sites?month=' + month + '&year=' + year + '&oak_score=' + score, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
  }

  public getallCompanySites(): Observable<any> {
    if (localStorage.getItem('token'))
      return this.httpClient.get(serviceBaseUrl + 'sites', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
    return of(null);
  }


  public getConsumptionavgLine(month: string, year: string): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'account/consumption/yearly/month?&month=' + month + '&year=' + year + '&limit=' +
        6 + '&avg=1',

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }

  public getConsumptionLine(month: string, year: string, site_slug: string, avg: boolean): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'site/consumption/yearly/month?&month=' + month + '&year=' + year + '&limit=' +
        6 +
        '&site_id=' + site_slug,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }



  public getConsumptionDaily(month: string, year: string, field: any): Observable<any> {
    if (localStorage.getItem('token')) {
      return this.httpClient.get(
        serviceBaseUrl +
        'account/insights/consumption/daily?&month=' + month + '&year=' + year + '&field=' + field
        ,

        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
    } else return of(null);
  }


  public getAppliancePhaseDistribution(month: string, year: string, site_id: string): Observable<any> {
    return this.httpClient.get(
      serviceBaseUrl +
      'insights/phase/distribution?month=' + month + '&year=' + year + '&site_id=' + site_id
      ,

      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

  public equipmentList(month: string, year: string, slug: any): Observable<any> {
    return this.httpClient.get(
      this.EquipmentApi +
      'equipment/1/' + slug + '/' + month + '/' + year,
      {
        headers: {
          Authorization: `Token ${'ee5753c0d996463b7b607fa61db6270951c74006'}`,
        },
      })
  }

  public multiDonutChat(month: string, year: string, slug: any) {
    return this.httpClient.get(this.EquipmentApi + 'equipment/2/' + slug + '/' + month + '/' + year,
      {
        headers: {
          Authorization: `Token ${'ee5753c0d996463b7b607fa61db6270951c74006'}`,
        },
      })
  }

  public equipment(slug: any) {
    return this.httpClient.get(this.EquipmentApi + 'equipment/' + slug,
      {
        headers: {
          Authorization: `Token ${'ee5753c0d996463b7b607fa61db6270951c74006'}`,
        },
      })
  }

  public nonOperationaline(month: string, year: string, slug: any, ids: any[]) {
    if (ids.length > 0)
      return this.httpClient.get(this.EquipmentApi + 'equipment/3/' + slug + '/' + month + '/' + year + '/[' + ids.join(',') + ']',
        {
          headers: {
            Authorization: `Token ${'ee5753c0d996463b7b607fa61db6270951c74006'}`,
          },
        })
    return of({ data: [] })
  }

  public runtimeline(month: string, year: string, slug: any, ids: any[]) {
    if (ids.length > 0)
      return this.httpClient.get(this.EquipmentApi + 'equipment/4/' + slug + '/' + month + '/' + year + '/[' + ids.join(',') + ']',
        {
          headers: {
            Authorization: `Token ${'ee5753c0d996463b7b607fa61db6270951c74006'}`,
          },
        })
    return of({ data: [] })
  }

  public bubble(month: string, year: string, slug: any, ids: any[]) {
    if (ids.length > 0)
      return this.httpClient.get(this.EquipmentApi + 'equipment/5/' + slug + '/' + month + '/' + year + '/[' + ids.join(',') + ']',
        {
          headers: {
            Authorization: `Token ${'ee5753c0d996463b7b607fa61db6270951c74006'}`,
          },
        })
    return of({ data: [] })
  }

  postsavings(datapass: any): Observable<any> {
    let bodyString = datapass;
    if (serviceBaseUrl == ('https://api.oak-insights.com/' as any))
      return this.httpClient.post(environment.SavingApi + 'insights/savings/projected', bodyString, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    else {
      return this.httpClient.post(environment.SavingApidemo + 'insights/savings/projected', bodyString, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
    }
  }

  public savingsgraph(month: any, year: any, site_id: string): Observable<any> {
    return this.httpClient.get(
      serviceBaseUrl +
      'insights/savings/graph?month=' + month + '&year=' + year + '&site_id=' + site_id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

  public futuremonth(slug: any): Observable<any> {
    return this.httpClient.get(
      serviceBaseUrl +
      'insights/savings/projected/month?' + 'siteid=' + slug,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

  public saverecommendations(): Observable<any> {
    return this.httpClient.get(
      serviceBaseUrl +
      'insights/savings/recommendations',
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

  public alert_notifications(slug: any): Observable<any> {
    return this.httpClient.get(
      this.SharedAlertsApi +
      'alert_notifications?site_id=' + slug,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }

  public report(slug: any): Observable<any> {
    return this.httpClient.get(
      serviceBaseUrl +
      'reports?site_slug=' + slug,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
  }


  postlastlogin(email: any, datedata: any): Observable<any> {
    let formdata = new FormData();
    formdata.append('email', email);
    formdata.append('lastLogin', datedata);
    return this.httpClient.post(`${serviceBaseUrl}auth/login/save`, formdata, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });
  }



  public getanomaly(slug: any): Observable<any> {
    return this.httpClient.get(
      environment.RecommendationsApi +
      'get_anomaly?site_id=' + slug,
      {
        headers: {
          Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
        },
      }
    );
  }

  public capexrecommendations(slug: any): Observable<any> {
    return this.httpClient.get(
      environment.RecommendationsApi +
      'capex_recommendations?site_id=' + slug,
      {
        headers: {
          Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
        },
      }
    );
  }

  public recommendations(slug: any): Observable<any> {
    return this.httpClient.get(
      environment.RecommendationsApi +
      'recommendations?site_id=' + slug,
      {
        headers: {
          Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
        },
      }
    );
  }

  public recommimprovement(slug: any): Observable<any> {
    return this.httpClient.get(
      environment.RecommendationsApi +
      'improvement_doc?site_id=' + slug,
      {
        headers: {
          Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
        },
      }
    );
  }
  public getmaintenance(slug: any): Observable<any> {
    return this.httpClient.get(
      environment.RecommendationsApi +
      'get_maintenance?site_id=' + slug,
      {
        headers: {
          Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
        },
      }
    );
  }

  public getmaintenancedevices(slug: any): Observable<any> {
    return this.httpClient.get(
      environment.RecommendationsApi +
      'get_devices?site_id=' + slug,
      {
        headers: {
          Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
        },
      }
    );
  }

  public capexemail(data: any): Observable<any> {
    return this.httpClient.get(
      data,
      {
        headers: {
          Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
        },
      }
    );
  }

  post(data: any): Observable<any> {

    return this.httpClient.post(`${environment.RecommendationsApi}post_anomaly`, data, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
      },
    });
  }

  postUploadDoc(site_id: any, files: any): Observable<any> {
    let formdata = new FormData();
    formdata.append('site_id', site_id);
    for (const file of files) {
      formdata.append('files', file, file.name); // Use a common key for multiple files
    }
    return this.httpClient.post(`${environment.RecommendationsApi}upload_improvement_doc`, formdata, {
      headers: {
        Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
      },
    });
  }


  postmaintenanceplanned(data: any): Observable<any> {
    return this.httpClient.post(`${environment.RecommendationsApi}plan_maintenance`, { comment: data.comment, ...this.clean(data) }, {
      headers: {
        Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
      },
    });
  }
  updateplanned(data: any): Observable<any> {
    return this.httpClient.post(`${environment.RecommendationsApi}update_maintenance_completion`, { comment: data.comment, ...this.clean(data) }, {
      headers: {
        Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
      },
    });
  }

  clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '') {
        delete obj[propName];
      }
    }
    return obj
  }

  deletemaintenanceplan(data: any): Observable<any> {
    return this.httpClient.post(`${environment.RecommendationsApi}delete_maintenance_plan`, data, {
      headers: {
        Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
      },
    });
  }
  deleteimprovementdoc(site_id: any, filename: any): Observable<any> {
    let formdata = new FormData();
    formdata.append('site_id', site_id);
    formdata.append('filename', filename);
    return this.httpClient.post(`${environment.RecommendationsApi}delete_improvement_doc`, formdata, {
      headers: {
        Authorization: `Bearer ${'204c1fedc145447c8142861c4f8597ce'}`,
      },
    });
  }
}